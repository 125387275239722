.stepper-wizard-list {
    display: flex;
    list-style-type: none;
    position: relative;
    z-index: 10;
    width: 80%;
    max-width: 500px;
    margin-bottom: 0px;
    li {
        max-width: 100%;
        position: relative;
        flex: 1;
        display: flex;
        &::after {
            content: '';
            width: 99%;
            position: absolute;
            border-top: 1px dashed $se-800;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .progress-point {
          font-size: .8em;
          height: 22px;
          width: 22px;
          border: 1px solid ;
          border-radius: 50%;
          line-height: 22px;
          text-align: center;
          background-color: #fff;
          z-index: 1000;
          position: relative;
          &.active {
            color: $pr-500;
            border-color: $pr-500;
          }
          &.completed {
              background-color: $pr-500;
              border: none;
          }
          img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 50%;
          }
        }
    }
    li:last-child {
        flex: 0;
        &::after {
            content: '';
            display: none;
        }
    }
}

.progressbar-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 43%;
  }
}

.step-names{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  gap: 3px;
  width: 94%;
  max-width: 500px;
}

.step-name {
  font-size: 11px;
  text-align: center;
  line-height: 14px;
}
