// .slick-list {
//   width: 100%;
// }
// .slick-track {
//   width: 100% !
// }

.left-align-slick > .slick-list > .slick-track {
    margin-left:0;
}
