.main-nav .navbar {
  white-space: nowrap;
  height: 56px;
  background: $main;
  padding: 0 1rem;
  .navbar-brand {
    background: url('../../../assets/images/branding/littlebirdie-logo-img.svg') no-repeat;
    width: 42px;
    height: 32px;
    @media (min-width: 1200px) {
      width: auto;
      height: auto;
      background: none;
    }
  }
 .btn-feed {
  font-size: 12px;
  font-family: $font-700;
  font-style: normal;
  font-weight: 500;
  height: 38px;
  line-height: 35px;
  padding: 0px 12px;
 }
 .mb-btn-feed {
  margin-top: 5px;
    padding-right: 15px;
 }
 .mb-man-icon {
  margin-top: 10px;
 }

  .navbar-brand img {
    width: 180px !important;
  }
  .header-img {
    min-width: 24px !important;
  }
  .new-pill {
    display: inline-flex;
    background-color: $pr-200;
    padding: 4px 8px;
    border-radius: 16px;
    margin-left: 3px;
  }
  .new-pill-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  @media (min-width: 1200px) {
    height: 88px;
    .navbar-nav {
      align-items: center;
    }
    .nav-listing, .nav-department {
      display: flex;
      align-items: center;
      margin-right: 0.25em;
      padding-left: 0.5em;
      padding-right: 0.5em;
      font-size: 12px;
      font-family:  $font-700;
      font-style: normal;
    }
  }
  @media (min-width: 1600px) {
    padding: 0 42px !important;
  }
  .nav-link, .nav-link:hover, .nav-link:active, .nav-link:focus {
    color: $se-800;
  }
  /****** DEPARTMENT MENU ******/
  .nav-department {
    .nav-link:after {
      border: 0;
      vertical-align: bottom;
    }
    .dep-icon {
      width: 24px;
      height: 24px;
      text-align: center;
      display: inline-block;
      margin-right: 0.5em;
    }
    @media (min-width: 1200px) {
      position: inherit;
      .dropdown-menu {
        width: 1200px;
        left: calc((100vw - 1200px)/2);
        margin: auto;
        padding: 0;
        border: 0;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08), 0px 24px 40px -4px rgba(0, 0, 0, 0.1);
        a {
          display: block;
        }
        .nav-category-see-all {
          font-family: $font-600;
          color: $pr-500;
          margin-bottom: 1.5em;
          margin-top: 36px;
          margin-left: 2em;
        }
        .nav-category-list {
          font-family: $font-600;
          li {
            padding: 0.25em 1em;
            border-bottom: 0;
            margin-left: 1em;
          }
        }
        .department-list {
          margin-top: 1.5em;
          margin-bottom: 1.5em;
          li {
            padding: 0.75em 1em;
            border-bottom: 0;
            margin-left: 1em;
            &.active, &:hover{
              // color: $main;
              text-decoration: underline;
            }
          }
        }
      }
    }
    @media (min-width: 1600px) {
      .dropdown-menu {
        width: 1440px;
        left: calc((100vw - 1440px)/2);
      }
    }
    .btn {
      font-size: 12px;
      font-family: $font-700;
      font-style: normal;
      font-weight: 500;
      height: 38px;
      line-height: 35px;
    }
    .btn-dept {
      border: 1px solid #808080;
      background-color: $main;
    }
    .btn-dept:hover {
      background-color: $main;
    }
  }
  .nav-
  .navbar-toggler {
    border: none;
    color: white;
    padding-left: 0;
    padding-right: 0;
    &:focus {
      outline: none;
    }
  }
  .mobile-menus {
    flex-wrap: initial;
    .navbar-brand {
      margin-right: 10px;
    }
  }
  .mobile-nav {
    padding-left: 10px;
    img {
      width: 5rem;
    }
  }
  .nav-profile {
    .nav-link:after {
      display: none;
    }
    .profile-img-sm {
      border: 2px solid $main;
      height: 42px;
      width: 42px;
      min-width: 42px;
    }
    .dropdown-header {
      color: $black;
      font-weight: 500;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .dropdown-item {
      font-size: 0.875em;
    }
    .sign-out {
      color: #f48545;
    }
    .dropdown-menu-profile {
      left : -96px
    }
  }

  .search-wrapper {
    .form-control {
      height: 40px;
      font-size: 14px;
      border: 1px solid $pr-500;
      border-radius: 20px ;
      color: #808080;
      padding-right: 40px;
      display: block;
      width: 100%;
    }
    width: calc(100% - 51px - 50px);
    &.full-size {
      width: 100vw;
    }
  }

  .mobile-burger {
    padding-left: 5px;
    padding-right: 9px;
    img {
      width: 30px;
    }
  }
}

.site-counter {
  .carousale-tracking {
    width: 100%;
    background: $main-100;
    text-align: center;
  }

  &.fluid {
    position: absolute;
  }
}

.shortcuts-list {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  background: #f7f7f7;
  text-align: center;
  -ms-overflow-style: none;  /* IE and Edge */
  height: 37px;
  line-height: 37px;
  scrollbar-width: none;  /* Firefox */
  .list-inline-item, .nav-item {
    padding-left: 20px;
    padding-right: 20px;
    // overflow: hidden;
    white-space: nowrap;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 1px;
      height: 15px;
      background: rgba(1, 33, 63, 0.29);
      top: 11px;
    }
    &:last-child:after {
      background: transparent;
    }
    &.active {
      border-bottom: 4px solid #08a469;
      &:before {
        position: absolute;
        width: 100%;
        height: 4px;
        content: "";
        bottom: 0;
        left: 0;
        background: #08a469;
      }
    }
  }
  .list-wrapper {
    height: 37px;
  }
}
/* Chrome, Safari and Opera */
.shortcuts-list::-webkit-scrollbar {
  display: none;
}

.content {
  // padding-top: 66px;
  padding-top: 1em;
  @media (min-width: 992px) {
    min-height: 80vh;
    // padding-top: 8.5em;
    position: relative;
  }
}


/****** SEARCH ******/
.form-search {
  width: -webkit-fill-available;
  margin-right: 20px;
  .input-group, .custom-select {
    width: 100%;
    .form-control {
      border: 1px solid $pr-500;
      border-radius: 20px ;
      color: #808080;
    }
    @media (min-width: 1200px) {
      .form-control {
        padding-right: 60px;
      }
    }
  }
}
.mb-search-bar-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
 }

// Popup Tooltips
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f01616;
}

.tooltip.show {
  opacity: 1 !important;
}

.red-tooltip {
  .tooltip-inner {
    background: #f01616;
    color: white;
  }
}



.dep-list {
  padding-left: 0;
  a {
    text-decoration: none;
    color: $black;
    &.link-green {
      color: $main
    }
  }
  li {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #ced4da;
    list-style: none;
    font-size: 1rem;
    margin: 5px 0;
    padding: 5px 0;
  }
}

