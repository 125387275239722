.is-cba {
  margin-bottom: 0rem !important;
}
.cba-card {
  text-align: left;
  &.shop-from-above {
    margin-bottom: 2.5em;
  }
  .page-title-section {
    font-family: $font-800;
    margin-top: 1.125rem;
    margin-bottom: 1.563rem;
    max-width: 20rem;
    @media (min-width: 768px) {
      max-width: 30rem;
    }
    .page-title {
      font-size: 2rem;
      line-height: 2rem;
      @media (min-width: 375px) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
    .cba-offer-txt {
      color: $secondary;
      margin-left: 0.35rem;
      font-size: 1.35rem;
      line-height: 1.75rem;
      @media (min-width: 375px) {
        margin-left: 0.25rem;
        font-size: 1.375rem;
        line-height: 1.875rem;
      }
    }
  }
  .cba-msg-text {
    max-width: 20rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    @media (min-width: 768px) {
      max-width: 30rem;
      font-size: 1rem;
    }
  }
  .cba-logo {
    width: 140px;
    @media (min-width: 992px) {
      width: 190px;
    }
  }
}

.cba-simple-register {
  .remember-chk {
      margin-top: 1.125rem;
      margin-bottom: 2.625rem;
      @media (min-width: 1200px) {
        margin-top: 1.125rem;
        margin-bottom: 1.125rem;
      }
  }
  hr {
    width: 105%;
    margin-left: -0.5rem;
    border-top: 1px solid $black-50;
    @media (min-width: 768px) {
      width: 115%;
      margin-left: -2rem;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  button {
    width: 132px;
  }
}

.mb-40 {
  margin-bottom: 1.25rem;
  @media (min-width: 768px) {
    margin-bottom: 2.5rem;
  }
}

.mt-40 {
  margin-top: 2rem;
  @media (min-width: 768px) {
    margin-top: .75rem;
  }
}

.text-budgee {
  color: $pr-500 !important
}

.login-tab {
  padding: 0;
  .nav-link {
    border-radius: 0;
    color: $se-400;
    padding-top: 1em;
    font-family: $font-600;
  }
  .nav-link.active {
    background-color: transparent;
    border-bottom: 2px solid $pr-500;
    color: $se-800;
  }
}

.error-label {
  background: #fff2ee;
  border: none;
  border-radius: 1em;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  .close {
    width: 17.5px;
    opacity: 1;
    img {
      width: 100%;
    }
  }
}

.MobileNav {
  height: 56px;
  cursor: pointer;
  margin-top: -56px;
  img {
    width: 1.2rem;
    margin-left: -3px;
    margin-right: 5px;
  }
  .title {
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
  }
}

.login-register {
  background-color: $main;
  margin-top: -1em;
  padding-bottom: 10rem;
  padding: 16px;
  height: 100%;

  &.login-bg {
    background-image: url("../../images/cloud2.png");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position-x: 140%, right;
    background-position-y: 1.5rem;

    @media (min-width: 768px) {
      min-height: 76.5rem;
      background-image: url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images//rays.svg");
      background-repeat: no-repeat;
      background-size: 24%, 32%, 20%, 35%, 25%, 20%, cover;
      background-position-x: -60px, 102%, 10%, 32%, 114%, -10%, center;
      background-position-y: 0, 15%, 30%, 80%, 90%, 85%, center;
    }
  }

  &.signup-bg {
    background-image: url("../../images/cloud2.png");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position-x: 145%, right;
    background-position-y: 6rem;
    padding-top: 0px;
    @media (min-width: 768px) {
      min-height: 76.5rem;
      background-image: url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images/cloud2.png"),
    url("../../images/cloud2.png"), url("../../images//rays.svg");
      background-repeat: no-repeat;
      background-size: 24%, 32%, 20%, 35%, 25%, 20%, cover;
      background-position-x: -60px, 102%, 10%, 32%, 114%, -10%, center;
      background-position-y: 0, 15%, 30%, 80%, 90%, 85%, center;
    }
  }

  .card-container {
    width: 100%;
    @media (min-width: 768px) {
      width: 468px;
    }

  }

  .card {
    padding: 15px 15px 0px 15px;
    .card-body {
      padding: 0px;
      margin-bottom: 12px;
      @media ( min-width: 768px) {
        margin-bottom: 74px;
      }
    }

    @media (min-width: 768px) {
      padding: 30px 30px 0px 30px;
    }
  }

  .social-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    padding: 12px 16px;
    border-radius: 8px;
  }

  .m-80 {
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }
  }
  .m-40 {
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      margin-bottom: 2.875rem;
    }
  }

  .device-header {
    text-align: center;
    margin-top: 8px;
    @media (min-width: 768px) {
      margin-top: 112px;
    }
    p {
      margin-top: 1rem;
      max-width: 17rem;
      text-align: left;
      font-weight: 400;
      color: $se-800;
      font-size: 1rem;
      @media (min-width: 768px) {
        margin: auto;
        margin-top: 25px;
        display: block;
        padding-left: 32px;
        padding-right: 32px;
        text-align: center;
        max-width: 20.813rem;
      }
    }
    .title {
      position: relative;
      padding: 0px;
      display: table;
      font-size: 2rem;
      line-height: 40px;
      background-image: url('../../../assets/images/interactions/flying-loop.gif');
      max-width: 18rem;
      overflow: visible;
      color: $secondary;
      text-align: left;
      font-family: "ABC Monument Grotesk Heavy", sans-serif;
      @media (min-width: 768px) {
        text-align: center;
        padding: 0px 32px;
      }
      &.login-background {
        font-size: 1.8rem;
        background-repeat: no-repeat;
        background-size: 20rem, cover;
        background-position-x: 5rem, right;
        background-position-y: 2.8rem, bottom;
        max-width: 20rem;
        @media (min-width: 360px) {
          font-size: 2rem;
          background-position-x: 6.5rem, right;
        }
        @media (min-width: 768px) {
          max-width: 30rem;
          font-size: 40px;
          margin: auto;
          background-size: 320px, cover;
          background-position-x: 16.5rem, right;
          background-position-y: 3rem, bottom;
        }
      }

      &.animation-1 {
        background-image: url('../../../assets//images/interactions/flying-off.gif');
      }


      &.signup-background {
        background-repeat: no-repeat;
        background-size: 320px, cover;
        background-position-x: 4rem, right;
        background-position-y: -1.3rem, top;
        padding-top: 1rem !important;
        @media (min-width: 360px) {
          font-size: 2.5rem;
          max-width: 22rem ;
          background-position-x: 7rem, right;
        }

        .spacing {
          word-spacing: 100vh;
        };

        @media (min-width: 768px) {
          margin: auto;
          max-width: 30rem;
          .spacing {
            word-spacing: normal;
          }
          background-position-x: 16.5rem, right;
          background-position-y: 1.5rem, bottom;
          &.bird-fly-position {
            background-position-y: 4rem, bottom;
          }
        }
      }
    }
     .para-margin {
       max-width: 23rem;
       @media (min-width: 768px) {
         margin-bottom: -30px;
       }
       @media (max-width: 390px) {
         max-width: 15rem;
       }
     }
    .signup-para-text {
      max-width: 23rem;
      @media (max-width: 390px) {
        max-width: 15rem;
      }
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.social-img {
  width: 18px;
  height: 18px;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.social-img-apple-id {
  width: 36px;
  height: 36px;
  margin-bottom: 11px;

  img {
    margin-left: -6px !important;
  }
}

.divider-text {
  position: relative;
  border: none;
  height: 1px;
  background: $black-500;
  margin-top: 24px;
  margin-bottom: 24px;
  &::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: $black-900;
    border-radius: 30rem;
    padding: 0rem 1rem;
    position: absolute;
    font-family: "ABC Monument Grotesk Heavy", sans-serif;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rounder-corners {
  border-radius: 25px;
}

.lh-20 {
  line-height: 20px;
}

.device-links {
  font-size: 10px;
  margin: 0px 5px 20px;
  line-height: 12px;
  color: $se-800;
  &.t-14 {
    font-size: 11px;
    @media (min-width: 375px) {
      font-size: 14px;
    }
  }
  a {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    padding: 0px;
  }
}
