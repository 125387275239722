.overlay-searchbar {
  display: none;
  @media (min-width: 1200px) {
    display: block;
    // position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1200;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    background: rgba(#000000, 0.4);
    overflow: hidden;
    .search-input-group {
      margin: auto;
      top: 16.65rem;
      max-width: 43.75rem;
      .form-control {
        height: 55px;
      }
      input {
        border-radius: 1.656rem !important;
      }
    }
  }
  .overlay-bird-animation {
    height: 100%;
    width: 100%;
    position: absolute;
    .bg-holder {
      margin: auto;
      margin-top: 3.625rem;
      width: 100%;
      height: 210px;
      max-width: 45rem;
      background-repeat: no-repeat;
      &.bird-fly-in {
        animation-play-state: initial;
        background-size: 110rem;
        background-position: right -34rem top -2.8rem;
        background-image: url('../../images/interactions/bird-fly-in-once-desktop-overlay.gif');
      }
      &.bird-main-move {
        background-image: url('../../images/interactions/bird-main-movement.gif');
        background-size: 30rem;
        background-position: right 0rem top -9.85rem;
      }
    }
  }
  .search-input-group {
    z-index: 1;
    .form-control {
      height: 44px !important;
      border-radius: 1.656rem;
      &::placeholder {
        color: transparent !important;
      }
      @media (min-width: 1200px){
        max-width: 43.57rem;
        height: 55px !important;
      }
    }
    #search-submit-btn {
      margin-top: 2px;
      img {
        height: 32px;
        width: 32px;
      }
    }
    .search-suggestions {
      margin-top: 1rem;
      border-radius: 8px;
      max-height: calc(100vh - 22rem);
      overflow: hidden;
      overflow-y: scroll;
      background-color: white;
      li:hover {
        background-color: rgba($main, 0.5);
      }
    }
  }
  &.input-focus {
    @media (min-width: 1200px) {
      .search-input-group {
        z-index: 1033;
      }
    }
  }

}

.homepage-listing {
  position: relative;
  z-index: 110;
  height: 100%;
  @media (min-width: 576px) {
    &.min-margin {
      margin-top: -50px;
    }
  }
  .section-top {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-top: 0px;
  }
}

.homesearch-section {
  @include clearfix;
  //height: 28.875rem;
  background: $main;
  border-radius: 0px 0px 32px 32px;
  padding: 2.5rem 1.625rem 1rem;
  overflow: hidden; // added for pseudo-element
  position: relative; // added for pseudo-element
  z-index: 10;
  @media (min-width: 1200px) {
    padding-top: 3.625rem;
    height: 36.5rem;
    // margin-bottom: -16.2rem;
    border-radius: 0px;
  }
  @media (min-width: 1900px) {

  }
  @media (max-width: 576px) {
    height: fit-content;
    padding-bottom: 20px;
  }
  .upsell-banner {
    margin-top: 40px;
    img {
      cursor: pointer;
      max-height: 200px;
      width: 100%;
      object-fit: contain;
    }
  }
  .cloud-img {
    height: 184px;
    object-fit: contain;
    position: absolute;
    left: -40px;
    @media (max-width: 576px) {
      display: none;
    }
  }
}

.upsell-banner {
  position: relative;
  z-index: 110;
  margin-top: 20px;
  img {
    cursor: pointer;
    width: 100%;
  }
}

.homepage-search {
  position: relative;
  &::before {
    content: ' ';
    position: fixed; // instead of background-attachment
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-image: url('../../images/cloud.png'), url('../../images/cloud.png') ;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 10.375rem, 10.375rem;
    background-position: left -4rem bottom 18rem, right -3rem bottom 22rem;
    will-change: transform; // creates a new paint layer
    z-index: -1;
    @media (min-width: 1200px) {
      padding-top: 3.625rem;
      height: 100%;
      border-radius: 0px;
      background-image: url('../../images/cloud.png'), url('../../images/cloud.png'), url('../../images/rays.svg');
      background-size: 12.375rem, 20.375rem, 100%;
      background-position: top 12.375rem left 3.25rem, top 11.375rem right 1.25rem, top -45rem center;
    }
    @media (min-width: 1900px) {
      background-size: 18.375rem, 30.375rem, 100%;
      background-position: top 9.375rem left 6.25rem, top 8.375rem right 4.25rem, top -45rem center;
    }
  }
  .search-content {
    padding: 0px;
    width: 100%;
    max-width: 45rem;
  }
  .search-bar {
    .text-animation {
      position: absolute;
      max-width: 20rem;
      height: 44px !important;
      margin-top: 0.70rem;
      z-index: 2;
      margin-left: 1rem;
      color: $secondary;
      @media (min-width: 1200px) {
        margin-top: 1rem;
        margin-left: 1.25rem;
      }
    }
    .homepage-search-input-group {
      max-width: 43rem;
      @media (min-width: 576px) {
        max-width: 38rem;
      }
      @media (min-width: 1200px) {
        max-width: 44rem;
      }
      .form-control {
        height: 44px;
        width: 90%;
        max-width: 38rem;
        background: white;
        border: 1.3248px solid $pr-500;
        border-radius: 1rem;
        @media (min-width: 1200px) {
          height: 55px;
          max-width: 43.57rem;
          border-radius: 1.656rem;
        }
      }
      .search-icon-img {
        display: flex;
        align-items: center;
        position: absolute;
        height: 40px;
        right: 0.75rem;
        border-radius: 50%;
        @media (min-width: 1200px) {
          height: 54px;
          right: 1rem;
        }
        img {
          height: 24px;
          width: 24px;
          @media (min-width: 1200px) {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
    .search-input-group {
      z-index: 1;
      .form-control {
        height: 44px !important;
        border-radius: 1.656rem;
        &::placeholder {
          color: transparent !important;
        }
        @media (min-width: 1200px){
          max-width: 43.57rem;
          height: 55px !important;
        }
      }
      #search-submit-btn {
        margin-top: 2px;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }
    &.input-focus {
      @media (min-width: 1200px) {
        .search-input-group {
          z-index: 1033;
        }
      }
    }
  }
  .msg-text {
    font-family: 'ABC Monument Grotesk Heavy', sans-serif;
    color: $secondary;
    font-size: 32px;
    line-height: 40px;
    background-repeat: no-repeat;
    background-size: 25rem;
    background-position: right -0.85rem bottom -34.25rem;
    @media (min-width: 576px) {
      font-size: 48px;
      line-height: 54px;
      background-position-x: 25.5rem;
      background-position-y: -5.75rem;
    }
    .pre-load {
      background-image: url('../../images/interactions/bird-main-movement.gif');
      background-size: 0rem;
    }
    // &.bird-hat {
    //   background-image: url('../../images/interactions/bird-with-hat.png');
    //   background-size: 3rem;
    //   background-position: right 2.15rem bottom 0.0rem;
    //   @media (min-width: 662px) {
    //     background-size: 3rem;
    //     background-position-x: 33rem;
    //     background-position-y: 8.3rem;
    //   }
    //   @media (min-width: 768px) {
    //     background-size: 4rem;
    //     background-position-x: 33rem;
    //     background-position-y: 4.1rem;
    //   }
    //   @media (min-width: 1200px) {
    //     background-size: 4rem;
    //     background-position-x: 38rem;
    //     background-position-y: 4.1rem;
    //   }
    // }
    &.bird-fly-in {
      background-image: url('../../images/interactions/bird-fly-in-once-new.gif');
      @media (min-width: 576px) {
        background-position-x: -29rem;
        background-position-y: -7rem;
        background-size: 100rem;
        background-image: url('../../images/interactions/bird-fly-in-once-desktop.gif');
      }
      @media (min-width: 1200px) {
        background-position-x: -24.5rem;
        background-position-y: -6.9rem;
        background-image: url('../../images/interactions/bird-fly-in-once-desktop.gif');
      }
    }
    &.bird-main-move {
      background-image: url('../../images/interactions/bird-main-movement.gif');
      background-position: right -1.85rem bottom -34.25rem;
      @media (min-width: 576px) {
        background-position-x: 14.75rem;
        background-position-y: -11.5rem;
      }
      @media (min-width: 768px) {
        background-size: 28rem;
        background-position-x: 12.15rem;
        background-position-y: -13.85rem;
      }
      @media (min-width: 1200px) {
        background-size: 30rem;
        background-position-x: 15.5rem;
        background-position-y: -15.35rem;
      }
    }
    .personal-msg {
      width: 17rem;
      padding-bottom: 0.75rem;
      @media (min-width: 576px) {
        width: 35rem;
      }
    }
  }
  .search-footer {
    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
      max-width: 43.57rem;
    }
  }
  .product-msg {
    font-size: 22px;
    line-height: 24px;
    color: $se-800;
    padding: 0.75rem 0;
    font-weight: 700;
    @media (max-width: 576px) {
      width: 30rem;
      font-size: 16px;
    }
    @media (min-width: 1200px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  .shop-by {
    button {
      display: flex;
      padding: 12px 16px;
      width: 207px;
      height: 48px;
      background: white;
      border: 1px solid $black-500;
      border-radius: 24px;
      border: 1px solid $black-500;
      font-family: 'ABC Monument Grotesk';
      font-size: 16px;
      line-height: 24px;
      color: $se-800;
    }
  }
  .welcome-link {
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    text-decoration-line: underline;
    color: $se-800;
    @media (min-width: 1200px) {
      font-size: 14px;
    }
    img {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
}

.advertisements-list+.homesearch-section {
  .homepage-search::before {
    background-position: left -4rem bottom 12.312rem, right -3rem bottom 16.312rem;

    @media (min-width: 992px) {
      background-position: left -4rem bottom 14.375rem, right -3rem bottom 18.375rem;
    }
    @media (min-width: 1200px) {
      background-position: top 16rem left 3.25rem, top 15rem right 1.25rem, top -45rem center;
    }
    @media (min-width: 1900px) {
      background-position: top 13rem left 6.25rem, top 12rem right 4.25rem, top -45rem center;
    }
  }
}